//This util varies from the current program price util used for courses
//in the way it needs to handle decimals
const formatProgramPrice = (
  programPrice: number = 0,
  currency: string = "USD",
) => {
  const hasDecimal = programPrice % 1 !== 0;

  const priceFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    maximumFractionDigits: 2,
    minimumFractionDigits: hasDecimal ? 2 : 0,
  });

  return priceFormat.format(programPrice);
};

export default formatProgramPrice;
