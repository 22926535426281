interface FormattedPriceProps {
  discountedPrice: number;
  originalPrice?: number;
  currency?: string;
  variant?: string;
}

function FormattedPrice({
  discountedPrice,
  originalPrice,
  currency,
  variant,
}: FormattedPriceProps) {
  const priceFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency || "USD",
    minimumFractionDigits: 2,
  });

  if (!originalPrice || discountedPrice === originalPrice) {
    return (
      <span
        className={variant === "light" ? "text-white" : ""}
      >{`${priceFormat.format(discountedPrice)} ${currency}`}</span>
    );
  }

  return (
    <>
      <span className={`font-bold ${variant === "light" ? "text-white" : ""}`}>
        <span className="sr-only">Discounted price:</span>
        {priceFormat.format(discountedPrice)}{" "}
      </span>
      <span
        className={`font-normal line-through ${variant === "light" ? "text-secondary-300" : ""}`}
      >
        <span className="sr-only">Original price:</span>
        {priceFormat.format(originalPrice)}
      </span>{" "}
      <span className="font-bold">{currency}</span>
    </>
  );
}

export default FormattedPrice;
