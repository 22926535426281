import { Enrollment } from "@/lib/partner-api/getEnrollments";

const isEnrolledInCourseWithCertificate = (
  courseRunKeys: string[] = [],
  userEnrollments: Enrollment[],
  applicableSeatTypes: string[] = [],
) =>
  userEnrollments.some((enrollment) => {
    // is enrolled in the course
    const isEnrolled = courseRunKeys.some(
      (key) => enrollment.courseDetails.courseId === key,
    );
    /**
     * Check if any applicableSeatTypes are a substring of an entitlement mode
     * necessary for the no-id-professional case, which should count as an applicableSeatType
     */
    const isApplicableSeatType = applicableSeatTypes.some((seatType) =>
      enrollment.mode.includes(seatType),
    );

    return isEnrolled && isApplicableSeatType;
  });

export default isEnrolledInCourseWithCertificate;
