import { ProgramType } from "../types";

const getProgramTypeString = (programType: ProgramType) => {
  switch (programType) {
    case ProgramType.MASTERS:
      return "Master's Degree";
    case ProgramType.MICROBACHELORS:
      return "MicroBachelors® Program";
    case ProgramType.MICROMASTERS:
      return "MicroMasters® Program";
    case ProgramType.PROFESSIONALCERTIFICATE:
      return "Professional Certificate";
    case ProgramType.XSERIES:
      return "XSeries Program";
    default:
      return "Program";
  }
};

export default getProgramTypeString;
