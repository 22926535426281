import { Program } from "../../types";
import {
  StructuredDataArray,
  Organization,
  CollegeOrUniversity,
  Offer,
  Course,
  CourseInstance,
  Website,
} from "@/types/Seo";
import { ProgramPricingData } from "../../../ecommerce/getProgramPricingData";
import getEnv from "@/utils/getEnv";
import truncateText from "@/utils/truncateText";
import stripHtmlTags from "@/utils/stripHtmlTags";
import { ProcessedCourse } from "../../../course/types";
import getProgramTypeString from "../getProgramTypeString";
import { CourseRun } from "@/lib/courseRun/types";
import { Duration, Price } from "../getDegreePricingAndDuration";

export const SITE_URL = getEnv("NEXT_PUBLIC_SITE_URL", "https://www.edx.org");
const PROVIDER_BASE_URL = `${SITE_URL}/school`;

const getDuration = (activeCourseRun: {
  minEffort: number | null;
  maxEffort: number | null;
}) => {
  const { minEffort, maxEffort } = activeCourseRun;

  const processedMinEffort = Number.isNaN(minEffort) ? 0 : Number(minEffort);
  const processedMaxEffort = Number.isNaN(maxEffort) ? 0 : Number(maxEffort);
  const effortAverage = Math.floor(
    (processedMinEffort + processedMaxEffort) / 2,
  );

  return `PT${effortAverage}H`;
};

const generateBaseStructuredData = (program: Program): StructuredDataArray => {
  const organizationSchema: Organization = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "edX",
    url: "https://www.edx.org",
    logo: "/images/logos/edx-logo-elm.svg",
    sameAs: [
      "https://www.facebook.com/edX",
      "https://twitter.com/edXOnline",
      "https://www.linkedin.com/company/edx",
      "http://www.instagram.com/edxonline",
      "https://www.youtube.com/user/edxonline",
    ],
  };
  const pageSchema: Website[] = [
    {
      "@context": "https://schema.org",
      "@type": "WebSite",
      url: `${SITE_URL}${program.marketingUrl}`,
      name: `${program.title} ${getProgramTypeString(program.productType)}`,
      alternateName: "edx Online",
    },
  ];

  return [pageSchema, organizationSchema];
};

export const generateProgramStructuredData = (
  program: Program,
  pricingData: ProgramPricingData | null,
  courses: ProcessedCourse[] | undefined = [],
): StructuredDataArray => {
  const baseSchema = generateBaseStructuredData(program);

  const provider: CollegeOrUniversity[] = program.partners?.length
    ? program.partners.map((partner) => ({
        "@type": "CollegeOrUniversity",
        name: partner.name,
        url: `${PROVIDER_BASE_URL}/${partner.slug}`,
      }))
    : [];

  const offers: Offer[] = pricingData
    ? [
        {
          "@type": "Offer",
          category: "Paid",
          priceCurrency: pricingData.currency,
          price:
            pricingData.discountedPrice?.toString() ??
            pricingData.originalPrice.toString(),
          eligibleRegion: "US",
        },
      ]
    : [];

  const courseRuns: CourseRun[] =
    courses
      ?.map((course) => course.activeCourseRun)
      .filter((course) => !!course) ?? [];

  const hasCourseInstance: CourseInstance[] = courseRuns.length
    ? courseRuns.map((courseRun) => {
        return {
          "@type": "CourseInstance",
          name: courseRun.title,
          instructor: courseRun.instructors?.map((instructor) => ({
            "@type": "Person",
            name: `${instructor.firstName} ${instructor.lastName}`,
            description: `${instructor.position} at ${instructor.partnerName}`,
          })),
          courseMode: "Online",
          courseSchedule: {
            "@type": "Schedule",
            duration: getDuration(courseRun),
            repeatFrequency: "Weekly",
            repeatCount: courseRun.weeksToComplete,
          },
        };
      })
    : [
        {
          courseMode: "Online",
          courseWorkload: "",
        },
      ];
  const publisher: Organization = {
    "@type": "Organization",
    name: "edX",
    url: "https://www.edx.org",
  };

  const hasPart: Course[] =
    courses?.map((course: ProcessedCourse) => {
      return {
        "@type": "Course",
        name: course.productName,
        url: `${SITE_URL}/${course.edxPath}`,
        description: truncateText(
          stripHtmlTags(course.productShortDescription),
        ),
      };
    }) ?? [];

  const courseSchema: Course = {
    "@context": "https://schema.org/",
    "@type": "Course",
    name: program.title,
    description: program.subtitle,
    provider,
    offers,
    hasCourseInstance,
    publisher,
    hasPart,
  };

  const structuredData: StructuredDataArray = [...baseSchema, courseSchema];

  return structuredData;
};

export const generateDegreeStructuredData = (
  program: Program,
  pricing: Price | null,
  duration: Duration | null,
): StructuredDataArray => {
  try {
    const seoData = program.customizations.contentDegreeDetailPage?.seo;
    const baseSchema = generateBaseStructuredData(program);

    const provider: CollegeOrUniversity[] = program.partners?.length
      ? program.partners.map((partner) => ({
          "@type": "CollegeOrUniversity",
          name: partner.name,
          url: `${PROVIDER_BASE_URL}/${partner.slug}`,
        }))
      : [];

    const offers: Offer[] = pricing
      ? [
          {
            "@type": "Offer",
            category: "Paid",
            priceCurrency: pricing.currency,
            price: pricing.amount.toString(),
            eligibleRegion: "US",
          },
        ]
      : [];

    const hasCourseInstance: CourseInstance[] | null = duration?.iso8601
      ? [
          {
            courseMode: "Online",
            courseWorkload: duration?.iso8601,
          },
        ]
      : null;
    const publisher: Organization = {
      "@type": "Organization",
      name: "edX",
      url: "https://www.edx.org",
    };

    const courseSchema: Course = {
      "@context": "https://schema.org/",
      "@type": "Course",
      name: seoData?.pageTitle ?? program.title,
      description: seoData?.pageDescription ?? program.subtitle,
      provider,
      offers,
      publisher,
      ...(hasCourseInstance && { hasCourseInstance }),
    };

    const structuredData: StructuredDataArray = [...baseSchema, courseSchema];

    return structuredData;
  } catch (error) {
    return [];
  }
};
