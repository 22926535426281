import { Children, ReactNode, cloneElement, isValidElement } from "react";

export default function attachPropsToChildren(
  children: ReactNode,
  props: Object,
) {
  return Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, props);
    }
    return null;
  });
}
