import { v4 as uuid } from "uuid";
import Testimonials from "@/components/Testimonials";
import Testimonial from "@/components/Testimonials/Testimonial";
import { ProgramCorporateEndorsement } from "@/lib/program/types";
import sortBySortValue from "@/utils/functions/sortBySortValue";

function EndorsementItem({
  endorsement,
  key,
}: {
  endorsement: ProgramCorporateEndorsement;
  key: string;
}) {
  if (endorsement.individualEndorsements.length === 0) {
    return null;
  }
  const individualEndorsement = endorsement.individualEndorsements[0];
  const endorser =
    individualEndorsement.endorser.length > 0
      ? individualEndorsement.endorser[0]
      : null;
  const image = endorsement.image.length > 0 ? endorsement.image[0] : null;
  const fullName =
    `${endorser?.givenName || ""} ${endorser?.familyName || ""}`.trim();
  const attribution = [fullName, endorser?.title].filter(Boolean).join(", ");

  return (
    <Testimonial
      id={key}
      attribution={attribution}
      variant="quote"
      imageUrl={image?.src || ""}
      imageAlt={endorsement.corporationName || ""}
      attributionPosition="bottom"
    >
      <p className="text-lg leading-9">{individualEndorsement.quote}</p>
    </Testimonial>
  );
}

interface ProgramEndorsementsProps {
  corporateEndorsements: ProgramCorporateEndorsement[];
}

function ProgramEndorsements({
  corporateEndorsements,
}: ProgramEndorsementsProps) {
  return (
    <div className="container max-w-screen-xl mx-auto">
      <Testimonials title="Program endorsements" variant="quote" show="all">
        {sortBySortValue(corporateEndorsements).map((endorsement) => {
          return <EndorsementItem key={uuid()} endorsement={endorsement} />;
        })}
      </Testimonials>
    </div>
  );
}

export default ProgramEndorsements;
