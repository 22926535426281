import React from "react";
import { AccordionTextProps } from "../../types";
import { Accordion } from "@/components/ui/accordion";
import EmphasizedText from "@/components/EmphasizedText";

const themeBackground = {
  default: "",
  light: "bg-putty-100",
};

export default function TitleLeft({
  title,
  children,
  titleEmphasis,
  theme = "default",
}: AccordionTextProps) {
  const childrenArray = React.Children.toArray(children).filter((child) =>
    React.isValidElement(child),
  );

  return (
    <div
      className={`container w-full flex flex-col lg:flex-row pt-6 pb-6 lg:pt-16 lg:pb-16 ${themeBackground[theme]}`}
    >
      {title && (
        <div>
          <h2 className="tracking-tighter text-primary elm-d2 mb-4 lg:pr-14 lg:mb-0 lg:mt-0.5">
            <EmphasizedText text={title} textEmphasis={titleEmphasis} />
          </h2>
        </div>
      )}
      <div className="flex flex-col w-full no-underlines">
        <Accordion
          type="multiple"
          className="flex flex-col gap-5 [&_button]:no-underline [&_button]:text-gray-dark [&_button:hover]:text-gray-dark"
        >
          {childrenArray}
        </Accordion>
      </div>
    </div>
  );
}
