"use client";
import { useMemo } from "react";
import IconDescription from "@/components/IconDescription";
import FormattedPrice from "@/components/ProgramDetailLayout/FormattedPrice";
import { IconTypes } from "@/components/icons/types";
import type { ProcessedCourse } from "@/lib/course/types";
import { PacingType, ProgramType } from "@/lib/program/types";
import { getExpertInstructionLabel } from "./utils";
import getTotalEstimatedEffortInHoursPerWeek from "@/lib/program/utils/getTotalEstimatedEffortInHoursPerWeek";
import getProgramPacing from "@/lib/program/utils/getProgramPacing";

interface StatProps {
  iconType: IconTypes;
  title: string | React.ReactNode;
  subtitle: string;
}

function Stat({ iconType, title, subtitle }: StatProps) {
  return (
    <li>
      <IconDescription
        iconType={iconType}
        title={title}
        subtitle={subtitle}
        textColor="text-primary"
      />
    </li>
  );
}

interface ProgramSummaryStatsProps {
  courses: ProcessedCourse[];
  creditValue?: number | null;
  currency?: string;
  discountedPrice?: number;
  duration?: string | null;
  originalPrice?: number;
  programType: ProgramType;
}

function ProgramSummaryStats({
  courses,
  creditValue,
  currency,
  discountedPrice,
  duration,
  originalPrice,
  programType,
}: ProgramSummaryStatsProps) {
  const pacingType = useMemo(() => getProgramPacing(courses), []);
  return (
    <ul className="list-none flex flex-col gap-4">
      <Stat
        iconType="library-books"
        title="Expert instruction"
        subtitle={`${courses.length} ${getExpertInstructionLabel(programType)} courses`}
      />
      {pacingType && pacingType !== "mixed" && (
        <Stat
          iconType="person"
          title={`${pacingType === PacingType.SELFPACED ? "Self-paced" : "Instructor-led"}`}
          subtitle={`${pacingType === PacingType.SELFPACED ? "Progress at your own speed" : "Assignments and exams have specific due dates"}`}
        />
      )}
      {duration && (
        <Stat
          iconType="clock"
          title={duration}
          subtitle={`${getTotalEstimatedEffortInHoursPerWeek(courses)}`}
        />
      )}
      {creditValue && (
        <Stat
          iconType="school"
          title={`${creditValue} Academic Credits`}
          subtitle="Build your skills and your transcript"
        />
      )}
      {discountedPrice && (
        <Stat
          iconType="credit-card"
          title={
            <FormattedPrice
              discountedPrice={discountedPrice}
              originalPrice={originalPrice}
              currency={currency}
            />
          }
          subtitle="For the full program experience"
        />
      )}
    </ul>
  );
}

export default ProgramSummaryStats;
