import Image from "next/image";

type Props = {
  id: string;
  attribution: string;
  imageUrl?: string;
  imageAlt?: string;
  children?: React.ReactNode;
};

export default function TestimonialQuote({
  id,
  attribution,
  imageUrl,
  imageAlt,
  children,
}: Props) {
  return (
    <div
      className="w-full flex flex-col bg-putty-200 border-2 border-putty-dark rounded p-8"
      key={id}
    >
      {imageUrl ? (
        <div className="flex justify-center items-center border border-putty-500 bg-white rounded-full w-[110px] h-[110px] mb-4">
          <div className="h-[80px] w-[80px] relative">
            <Image
              src={imageUrl}
              alt={imageAlt || ""}
              objectFit="contain"
              fill
            />
          </div>
        </div>
      ) : (
        <h3 className="text-lg font-bold mb-3">{imageAlt}</h3>
      )}
      <div>{children}</div>
      <p className="text-sm">{attribution}</p>
    </div>
  );
}
