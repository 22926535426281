"use client";
import FullSkeleton from "@/components/ui/skeleton/variants/fullPageSkeleton";
import useProgramRedesignTraffic from "@/monarch/rules/programRedesignTraffic/useProgramRedesignTraffic";

interface WithMonarchProps {
  Control: React.ReactNode;
  TestComponent: React.ReactNode;
  Loader?: React.ReactNode;
}

export const MonarchResolver = ({
  Control,
  TestComponent,
  Loader,
}: WithMonarchProps) => {
  const { isLoading, showRedesign } = useProgramRedesignTraffic();
  if (isLoading && Loader) {
    return Loader;
  }

  if (isLoading) {
    return <FullSkeleton />;
  }

  return showRedesign ? TestComponent : Control;
};
