"use client";
import { useEffect, useState, useRef } from "react";
import Image from "next/image";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import { PartialPartner } from "@/lib/partner/types";
import FormattedPrice from "@/components/ProgramDetailLayout/FormattedPrice";
import { Program } from "@/lib/program/types";
import { ProcessedCourse } from "@/lib/course/types";
import getEnrollmentUrl from "@/lib/program/utils/programEnroll/getEnrollmentUrl";
import useClientEnrollments from "@/lib/partner-api/useClientEnrollments";
import useClientEntitlements from "@/lib/partner-api/useClientEntitlements";
import CTALink from "@/components/CTA/variants/CtaLink";
import { programMap } from "@/lib/program/constants";

type Props = {
  title: string;
  marketingHook: string;
  owners: PartialPartner[];
  path: string;
  type: string;
  backgroundImage?: string;
  isProgramEnrollable?: boolean;
  originalPrice?: number;
  discountedPrice?: number;
  currency?: string;
  program: Program;
  courses: ProcessedCourse[];
  skus: string[];
  ctaText: string;
  subject: string;
  conversionType: string | undefined;
  emailOptIn?: boolean;
};

export default function HeroProgramsCtaTextTest({
  title,
  marketingHook,
  owners,
  path,
  type,
  backgroundImage = "https://www.edx.org/images/experiments/ProgramDetails/data-science/hook-background-lg.jpg",
  isProgramEnrollable = true,
  originalPrice,
  discountedPrice,
  currency,
  program,
  courses,
  skus,
  ctaText,
  subject,
  conversionType,
  emailOptIn = false,
}: Props) {
  const defaultHook =
    programMap[type]?.marketingHook || programMap["default"].marketingHook;
  const subTitle = programMap[type]?.type || programMap["default"].type;

  const stickyRef = useRef<HTMLDivElement>(null);
  const [isAtTop, setIsAtTop] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (stickyRef.current) {
        const rect = stickyRef.current.getBoundingClientRect();
        // Check if the element's top is at or above the viewport's top
        setIsAtTop(rect.top <= 0);
      }
    };

    window.addEventListener("scroll", handleScroll);
    // Initial check in case the element is already at the top
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { enrollments } = useClientEnrollments();
  const { entitlements } = useClientEntitlements();

  const enrollmentUrl = getEnrollmentUrl(
    program,
    courses,
    skus,
    enrollments,
    entitlements,
    emailOptIn,
  );

  const { productType, partners } = program;
  const brand = partners?.[0].key || "";

  return (
    <div className="justify-center relative">
      <div className="absolute inset-0 z-[-2] bg-putty-200">
        {backgroundImage && (
          <Image
            src={backgroundImage}
            layout="fill"
            alt="Gray decorative background design"
            className="object-cover object-center"
          />
        )}
      </div>

      <div className="text-putty-foreground pt-12 pb-1 px-4 container">
        <div className="text-light-foreground mb-6">
          <Breadcrumbs variant="dark" path={path} onlyShowLastLink />
        </div>
      </div>

      <div
        ref={stickyRef}
        className="overflow-hidden shadow-md shadow-slate-600"
      >
        {!isAtTop && (
          <div className="min-h-[100px] mx-auto">
            <div className="lg:container flex p-0">
              <div
                className="bg-white flex flex-col items-start p-0 md:relative md:flex-row md:items-center md:p-0 md:w-full md:p-0 
              before:absolute before:w-[300px] before:top-0 before:bottom-0 before:left-[-300px] before:bg-white before:hidden before:md:block 
              after:absolute lg:after:w-[300px] after:top-0 after:bottom-0 after:right-[-300px] after:bg-primary-500 after:hidden after:md:block"
              >
                <div className="flex">
                  {owners.map((owner) => (
                    <div key={owner.key} className="w-[180px] px-4">
                      <Image
                        className="mr-2"
                        width={180}
                        height={100}
                        src={owner.logoUrl || ""}
                        alt={owner.name || ""}
                      />
                    </div>
                  ))}
                </div>
                <div className="py-6 px-4 text-base text-white bg-primary-500 w-screen md:w-full flex flex-col md:flex-row md:items-center gap-3">
                  <div>
                    <span>{subTitle}</span>
                    <span className="text-xl block">{title}</span>
                  </div>
                  <CTALink
                    label={ctaText}
                    type="brand"
                    url={enrollmentUrl}
                    size="lg"
                    className="md:ml-auto [&>span]:whitespace-nowrap"
                    enable_tracking={false}
                    conversionTrackingInfo={{
                      productName: title,
                      brand: brand,
                      variant: productType,
                      category: subject,
                      customName: conversionType,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {isAtTop && (
          <div className="fixed top-0 flex justify-center items-center bg-primary-500 w-full h-16 z-50">
            <CTALink
              label={ctaText}
              type="brand"
              url={enrollmentUrl}
              size="lg"
              className="ml-6"
              enable_tracking={false}
              conversionTrackingInfo={{
                productName: title,
                brand: brand,
                variant: productType,
                category: subject,
                customName: conversionType,
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
