"use client";
import AccordionText from "@/components/AccordionText";
import AccordionTextItem from "@/components/AccordionText/AccordionTextItem";
import InstructorsList from "@/components/InstructorList";
import HeroPrograms from "@/components/ProductHero/Programs";
import ProgramContentBlock from "./ProgramContentBlock";
import ProgramEndorsements from "./ProgramEndorsements";
import ProgramRelatedContent from "./ProgramRelatedContent";
import ProgramSummary from "./ProgramSummary";
import ProgramEnroll from "./ProgramSummary/components/ProgramEnroll";
import getProgramDuration from "@/lib/program/utils/getProgramDuration";
import sortBySortValue from "@/utils/functions/sortBySortValue";
import { staticOfacRestriction } from "./constants";
import { ProgramDetailContentProps } from "./types";
import { getAllInstructorsFromCourse } from "@/lib/instructor/getAllInstructorsFromCourse";
import useEmailOptIn from "./ProgramDetailRefresh/ProgramDetailContext/hooks/useEmailOptIn";

export default function ProgramDetailContent({
  programData,
  courses,
  pricingData,
  skus,
  programIsEnrollable,
}: ProgramDetailContentProps) {
  const { emailOptIn } = useEmailOptIn();

  const firstEnglishSubject = programData.subjects.find(
    (subject) => subject.languageCode === "en",
  );
  const programSubject =
    programData.subjectEn || firstEnglishSubject?.name || "";

  const uniqueInstructors = getAllInstructorsFromCourse(courses);
  const duration = courses && getProgramDuration(courses);

  let faqItems = programData.faq
    ? sortBySortValue(programData.faq || []).map((item, index) => (
        <AccordionTextItem key={index} trigger={item.question || ""}>
          <p>{item.answer}</p>
        </AccordionTextItem>
      ))
    : null;

  if (programData.hasOfacRestrictions) {
    faqItems?.unshift(
      <AccordionTextItem trigger={staticOfacRestriction.trigger}>
        <p>{staticOfacRestriction.description} </p>
      </AccordionTextItem>,
    );
  }

  return (
    <>
      <HeroPrograms
        title={programData?.title || ""}
        marketingHook={programData?.marketingHook || ""}
        owners={programData?.partners || []}
        path={programData?.marketingUrl || ""}
        type={programData?.productType || ""}
        isProgramEnrollable={
          programIsEnrollable && programData.programEligibleForOneClickPurchase
        }
        program={programData}
        courses={courses || []}
        skus={skus}
        originalPrice={pricingData?.originalPrice}
        discountedPrice={pricingData?.discountedPrice}
        currency={pricingData?.currency}
        subject={programSubject}
        emailOptIn={emailOptIn}
      />
      <ProgramSummary
        program={programData}
        courses={courses || []}
        currency={pricingData?.currency}
        discountedPrice={pricingData?.discountedPrice}
        duration={duration}
        originalPrice={pricingData?.originalPrice}
      />

      <InstructorsList
        instructors={uniqueInstructors}
        partners={programData?.partners}
        variant="text-right"
      />
      {programData.corporateEndorsements.length > 0 && (
        <ProgramEndorsements
          corporateEndorsements={programData.corporateEndorsements}
        />
      )}
      {programData.programEligibleForOneClickPurchase &&
        programIsEnrollable && (
          <ProgramEnroll
            courses={courses || []}
            programData={programData}
            subject={programSubject}
            originalPrice={pricingData?.originalPrice}
            discountedPrice={pricingData?.discountedPrice}
            currency={pricingData?.currency}
            duration={duration || null}
            skus={skus}
          />
        )}
      {faqItems && faqItems.length > 0 ? (
        <div className="flex flex-col h-full container bg-center">
          <AccordionText title="FAQs" theme="light" variant="default">
            {faqItems}
          </AccordionText>
        </div>
      ) : null}

      <ProgramRelatedContent programSubject={programSubject} />
      <ProgramContentBlock />
    </>
  );
}
