import { ProcessedCourse } from "@/lib/course/types";
import getProductForCourse from "./getProductForCourse";

const getSkuQueryStringForPartialBundle = (
  courses: ProcessedCourse[],
  applicableSeatTypes: string[],
) =>
  courses.reduce((queryString, courseNode) => {
    const entitlement = getProductForCourse(courseNode, applicableSeatTypes);

    if (entitlement && entitlement.sku) {
      return `${queryString}&sku=${encodeURIComponent(entitlement.sku)}`;
    }

    return queryString;
  }, "");

export default getSkuQueryStringForPartialBundle;
