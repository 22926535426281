import React from "react";
import getSubjectToUse from "@/components/ProgramDetailLayout/ProgramRelatedContent/utils/getSubjectToUse";
import getRelatedTopics from "@/components/ProgramDetailLayout/ProgramRelatedContent/utils/getRelatedTopics";
import { Badge } from "@/components/ui/badge";
import LinkWithChildren from "@/components/Link/LinkWithChildren/LinkWithChildren";
import { ElementType } from "@/constants/tagular/main";

function ProgramRelatedContent({ programSubject }: { programSubject: string }) {
  const subjectToUse = getSubjectToUse(programSubject);
  if (!subjectToUse) {
    return null;
  }
  const relatedTopics = getRelatedTopics(subjectToUse);

  return (
    <div>
      <h2 className="text-2xl font-bold mt-0 mb-4">Browse related topics</h2>
      <div className="flex flex-row gap-2 flex-wrap">
        {relatedTopics.map((skill, index) => (
          <LinkWithChildren
            item={{ href: skill.href, altText: skill.label }}
            html_id="topic-link"
            index={index}
            key={skill.label}
            element_type={ElementType.Entry}
            location="related-topics"
          >
            <Badge
              key={skill.label}
              className="whitespace-nowrap bg-putty-200 border-putty-400 text-primary-500 !rounded !shadow-none"
            >
              {skill.label}
            </Badge>
          </LinkWithChildren>
        ))}
      </div>
    </div>
  );
}

export default ProgramRelatedContent;
