import type { TestimonialProps } from "../types";
import TestimonialDark from "./variants/dark";
import TestimonialLight from "./variants/light";
import TestimonialDefault from "./variants/default";
import TestimonialQuote from "./variants/quote";

export default function Testimonial({
  variant = "dark",
  ...props
}: TestimonialProps) {
  switch (variant) {
    case "dark":
      return <TestimonialDark {...props} />;
    case "light":
      return <TestimonialLight {...props} />;
    case "quote":
      return <TestimonialQuote {...props} />;
    case "default":
      return <TestimonialDefault {...props} />;
    default:
      return null;
  }
}
