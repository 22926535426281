import { CertificateCTATextProps, UseCertificateCTAText } from "./types";
import { useMonarchRule } from "../MonarchRulesProvider";

const CUSTOM_RULE_ID = "certificateCtaRule";

const useCertificateCTAText: UseCertificateCTAText = (options) => {
  const { isLoading, value } = useMonarchRule<{
    data: CertificateCTATextProps;
    variantCustomId: string;
  }>(CUSTOM_RULE_ID, options);

  if (isLoading) {
    return {
      isLoading,
      ctaText: undefined,
      conversionType: undefined,
    };
  }

  return {
    isLoading,
    ctaText: value?.data?.ctaText,
    conversionType: value?.data?.eventing?.conversionType,
  };
};

export default useCertificateCTAText;
