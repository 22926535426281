import React from "react";
import DefaultAccordion from "./variants/Default";
import Pathway from "./variants/Pathway";
import TitleLeft from "./variants/TitleLeft";
import { AccordionTextProps } from "./types";
import TitleTop from "./variants/TitleTop";

export default function AccordionText(props: AccordionTextProps) {
  const { variant } = props;
  switch (variant) {
    case "pathway":
      return <Pathway {...props} />;
    case "title-left":
      return <TitleLeft {...props} />;
    case "title-top":
      return <TitleTop {...props} />;
    default:
      return <DefaultAccordion {...props} />;
  }
}
