import { CourseEntitlement } from "@/lib/course/types";

/**
 * Retrieve an entitlement with applicable type from the course entitlements.
 */
const getApplicableEntitlement = (
  entitlements: CourseEntitlement[] = [],
  applicableSeatTypes: string[],
) =>
  entitlements.find((entitlement) => {
    const hasApplicableSeatType = applicableSeatTypes.includes(
      entitlement.mode || "",
    );
    if (!entitlement.expires) {
      return hasApplicableSeatType;
    }
    const today = Date.now();
    const expiration = new Date(entitlement.expires).getTime();
    return expiration > today && hasApplicableSeatType;
  });

export default getApplicableEntitlement;
