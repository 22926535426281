import Image from "next/image";

import styles from "./index.module.css";
import icon from "@/public/icons/testimonial-icon.svg";

type Props = {
  id: string;
  attribution: string;
  program?: string;
  children?: React.ReactNode;
};

export default function TestimonialLight({
  id,
  attribution,
  program,
  children,
}: Props) {
  return (
    <div
      key={id}
      className="rounded flex flex-col basis-0 grow shrink justify-between w-full lg:w-auto lg:max-w-[400px] bg-light/60 border p-6"
    >
      <div className={styles.content}>{children}</div>
      <div className="flex shrink-0 items-start border-t pt-6 gap-6">
        <div className="flex shrink-0">
          <Image src={icon} height={67} width={67} alt="" className="m-0" />
        </div>
        <div>
          <p className="font-bold m-0">{attribution}</p>
          {program && <p className="m-0">{program}</p>}
        </div>
      </div>
    </div>
  );
}
