import React from "react";
import Icon from "@/components/icons";
import attachPropsToChildren from "@/utils/functions/attachPropsToChildren";
import type { TestimonialsProps } from "./types";

export default function Testimonials({
  heading = "The reviews are in",
  title,
  titleAlign = "center",
  titleSize = "small",
  variant = "default",
  showReviewHeadline = false,
  children,
  show = 3,
}: TestimonialsProps) {
  const reviewHeadlineStyle = showReviewHeadline
    ? "italic font-black mb-10"
    : "";
  const validChildren = attachPropsToChildren(children, { variant });

  const testimonials =
    validChildren && validChildren.length > 0
      ? show === "all"
        ? validChildren
        : validChildren.slice(0, show)
      : validChildren;

  const titleAlignVariants = {
    center: "text-center",
    left: "text-left",
    right: "text-right",
  };

  const titleSizeVariants = {
    large: "text-6xl",
    small: "text-2xl",
  };

  const getContainerClasses = () => {
    switch (variant) {
      case "default":
        return "md:items-stretch md:flex-row";
      case "light":
      case "dark":
        return "lg:items-stretch lg:flex-row";
      default:
        return "items-stretch";
    }
  };
  const containerClasses = getContainerClasses();

  return (
    <div className="py-16">
      {showReviewHeadline && (
        <h2 className="text-3xl text-primary font-bold mb-4">{heading}</h2>
      )}
      {title && (
        <h2
          className={`${titleAlignVariants[titleAlign]} ${titleSizeVariants[titleSize]} ${reviewHeadlineStyle}`}
        >
          {title}
        </h2>
      )}
      {variant === "quote" && (
        <div className="mb-4 xl:-ml-8">
          <Icon type="format-quote" svgProps={{ width: 48, height: 48 }} />
        </div>
      )}
      <div
        className={`grid ${variant === "quote" ? "lg:grid-cols-2" : "md:grid-cols-3"} gap-8 items-center ${containerClasses}`}
      >
        {testimonials}
      </div>
    </div>
  );
}
