import getEnv from "../getEnv";

const enablePrograms = getEnv(
  "NEXT_PUBLIC_COMMERCE_COORDINATOR_ENABLE_PROGRAMS",
);
const commerceUrlBase = getEnv("NEXT_PUBLIC_COMMERCE_COORDINATOR_BASE_URL");

export function getCommerceUrlBase(fallbackUrl = ""): string {
  return commerceUrlBase || fallbackUrl;
}

export function isCoordinatorEnabledForPrograms(): boolean {
  return enablePrograms !== "false";
}
