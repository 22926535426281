"use client";
import type { ProcessedCourse } from "@/lib/course/types";
import type { Program } from "@/lib/program/types";
import ProgramEnrollButton from "../ProgramEnrollButton";
import EmailOptIn from "@/components/EmailOptIn/EmailOptIn";
import useEmailOptIn from "../ProgramDetailContext/hooks/useEmailOptIn";
import formatProgramPrice from "../utils/formatProgramPrice";
import useProgramRedesignTraffic from "@/monarch/rules/programRedesignTraffic/useProgramRedesignTraffic";

type ProgramEnrollProps = {
  courses: ProcessedCourse[];
  programData: Program;
  originalPrice?: number;
  discountedPrice?: number;
  currency?: string;
  duration: string | null;
  skus: string[];
};

export default function ProgramEnroll({
  courses,
  programData,
  originalPrice,
  discountedPrice,
  currency,
  duration,
  skus,
}: ProgramEnrollProps) {
  const { emailOptIn, setEmailOptIn } = useEmailOptIn();
  const { programEnrollCta } = useProgramRedesignTraffic();
  const { partners, title } = programData;
  const count = courses.length;
  const originalPriceSize =
    programEnrollCta?.pricingTypographySize?.originalPrice;
  const discountedPriceSize =
    programEnrollCta?.pricingTypographySize?.discountedPrice;
  const strikethroughClass =
    programEnrollCta?.strikethroughToggle !== false ? "line-through" : "";

  return (
    <div
      id="program-enrolling-now-section"
      className="flex flex-col md:flex-row"
    >
      <div className="bg-putty-400 py-10 md:py-28 px-8">
        <div className="flex flex-col md:flex-row gap-4 justify-center md:justify-start">
          <h2 className="m-0 text-3xl text-center md:text-left">
            {programEnrollCta?.enrollingNowLanguage || "Enrolling Now"}
          </h2>
          <div>
            <ProgramEnrollButton
              programData={programData}
              courses={courses}
              skus={skus}
            />
          </div>
        </div>
        <div className="pt-6 md:pr-32">
          <EmailOptIn
            title={title || ""}
            partners={partners}
            optIn={emailOptIn}
            variant="secondary"
            toggleEmailOptIn={() => {
              setEmailOptIn(!emailOptIn);
            }}
            checkboxId="email-opt-in-program"
          />
        </div>
      </div>

      <div className="bg-primary-500 p-10 flex justify-end items-center text-white">
        <div className="flex flex-col">
          <div className="font-bold text-white italic mb-4 text-right">
            {originalPrice &&
            discountedPrice &&
            originalPrice !== discountedPrice ? (
              // Both prices are available and different, show original with line-through
              <div
                className={`${strikethroughClass} ${originalPriceSize ? `text-${originalPriceSize}` : "text-5xl"} opacity-50 mb-6`}
              >
                <span className="sr-only">Original price:</span>
                {`${formatProgramPrice(originalPrice, currency)}${currency}`}
              </div>
            ) : originalPrice &&
              (!discountedPrice || originalPrice === discountedPrice) ? (
              // Only original price available OR prices are equal, show single price without line-through
              <div className="mb-6">
                <span className="sr-only">Price:</span>
                {`${formatProgramPrice(originalPrice, currency)}${currency}`}
              </div>
            ) : null}

            {discountedPrice && originalPrice !== discountedPrice ? (
              <div
                className={`${discountedPriceSize ? `text-${discountedPriceSize}` : "text-5xl"}`}
              >
                <span className="sr-only">
                  {originalPrice ? "Discounted price:" : "Price:"}
                </span>
                {`${formatProgramPrice(discountedPrice, currency)}${!originalPrice ? currency : ""}`}
              </div>
            ) : null}
          </div>
          <div className="font-bold text-right">
            {count} {count > 1 ? "courses" : "course"} in {duration}
          </div>
          {/* TODO: Need to define behavior for this, and when it should show, hidden for now */}
          {/* <div className="mt-6 flex gap-1 justify-end text-sm items-center">
            <span>Financial aid</span>
            <Info svgProps={{ width: 18, height: 18 }} />
          </div> */}
        </div>
      </div>
    </div>
  );
}
