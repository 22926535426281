import { cn } from "@/lib/utils";
import React from "react";

interface EmphasizedTextProps {
  text: string;
  textEmphasis: string | undefined;
  emphasisClass?: string;
}

const EmphasizedText: React.FC<EmphasizedTextProps> = ({
  text,
  textEmphasis,
  emphasisClass = "",
}) => {
  if (!textEmphasis) return <>{text}</>;

  const parts = text.split(textEmphasis);

  return (
    <>
      {parts.map((part, index) => (
        <React.Fragment key={index}>
          {part}
          {index < parts.length - 1 && (
            <span className={cn("text-brand", emphasisClass)}>
              {textEmphasis}
            </span>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default EmphasizedText;
