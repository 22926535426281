import React from "react";
import { Accordion } from "@/components/ui/accordion";
import { createIdFromLabel } from "@/utils/functions/getIdLabel";
import styles from "./index.module.css";

type Props = {
  children?: React.ReactNode;
  title?: string;
};

export default function TitleTop({ title, children }: Props) {
  // Only get valid Elements (without ,)
  const childrenArray = React.Children.toArray(children).filter((child) =>
    React.isValidElement(child),
  );
  const titleId = title ? createIdFromLabel(title) : "";
  return (
    <>
      {title && <a className="subnav-item" id={titleId}></a>}
      <div className="flex flex-col py-6">
        {title && (
          <h2 className="mt-2 mb-4 pb-4 text-[1.375rem] leading-[1.75rem] font-medium uppercase border-b border-putty-300">
            {title}
          </h2>
        )}
        <Accordion
          type="multiple"
          className="flex flex-col gap-5 [&_button]:no-underline [&_button]:text-gray-dark [&_button:hover]:text-gray-dark"
        >
          {childrenArray}
        </Accordion>
      </div>
    </>
  );
}
