const getRelatedTopics = (subject: string) => {
  switch (subject) {
    case "Data Science":
      return [
        {
          label: "Data Analysis",
          href: "/learn/data-analysis",
        },
        {
          label: "Excel",
          href: "/learn/excel",
        },
        {
          label: "Machine Learning",
          href: "/learn/machine-learning",
        },
        {
          label: "R Programming",
          href: "/learn/r-programming",
        },
        {
          label: "SQL",
          href: "/learn/sql",
        },
      ];
    case "Computer Science":
      return [
        {
          label: "App Development",
          href: "/learn/app-development",
        },
        {
          label: "Blockchain",
          href: "/learn/blockchain",
        },
        {
          label: "C Programming",
          href: "/learn/c-programming",
        },
        {
          label: "Computer Programming",
          href: "/learn/computer-programming",
        },
        {
          label: "Cybersecurity",
          href: "/learn/cybersecurity",
        },
        {
          label: "Information Technology",
          href: "/learn/information-technology",
        },
        {
          label: "Java",
          href: "/learn/java",
        },
        {
          label: "Linux",
          href: "/learn/linux",
        },
        {
          label: "Python",
          href: "/learn/python",
        },
        {
          label: "Web Development",
          href: "/learn/web-development",
        },
      ];
    default:
      return [];
  }
};

export default getRelatedTopics;
