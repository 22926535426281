const getSubjectToUse = (programSubject: string) => {
  if (
    programSubject === "Data Analysis & Statistics" ||
    programSubject === "Data Science"
  ) {
    return "Data Science";
  }
  if (programSubject === "Computer Science") {
    return "Computer Science";
  }
  return "";
};

export default getSubjectToUse;
