"use client";
import { Checkbox } from "@/components/ui/checkbox";
import CTALink from "@/components/CTA/variants/CtaLink";
import FormattedPrice from "@/components/ProgramDetailLayout/FormattedPrice";
import type { ProcessedCourse } from "@/lib/course/types";
import useClientEnrollments from "@/lib/partner-api/useClientEnrollments";
import useClientEntitlements from "@/lib/partner-api/useClientEntitlements";
import type { Program } from "@/lib/program/types";
import { getBrandString } from "@/utils/tagular/helpers";
import useCertificateCTAText from "@/monarch/rules/certificateCTA/useCertificateCTAText";
import { Skeleton } from "@/components/ui/skeleton";
import getEnrollmentUrl from "@/lib/program/utils/programEnroll/getEnrollmentUrl";
import useEmailOptIn from "@/components/ProgramDetailLayout/ProgramDetailRefresh/ProgramDetailContext/hooks/useEmailOptIn";

type ProgramEnrollProps = {
  courses: ProcessedCourse[];
  programData: Program;
  originalPrice?: number;
  discountedPrice?: number;
  currency?: string;
  duration: string | null;
  subject: string;
  skus: string[];
};

export default function ProgramEnroll({
  courses,
  programData,
  originalPrice,
  discountedPrice,
  currency,
  duration,
  subject,
  skus,
}: ProgramEnrollProps) {
  const { enrollments } = useClientEnrollments();
  const { entitlements } = useClientEntitlements();
  const { isLoading, ctaText, conversionType } = useCertificateCTAText({
    isProgramEnrollable: true,
  }); //This is only ever rendered if the program is enrollable

  const { emailOptIn, setEmailOptIn } = useEmailOptIn();

  const { partners, productType, title } = programData;
  const brand = getBrandString(programData);
  const count = courses.length;

  const partnerNames = partners
    .map((p) => p.key)
    .reduce((acc, key, index, array) => {
      if (index === array.length - 1) {
        return `${acc} & ${key}`;
      }
      return `${acc}, ${key}`;
    });

  const enrollmentUrl = getEnrollmentUrl(
    programData,
    courses,
    skus,
    enrollments,
    entitlements,
    emailOptIn,
  );

  return (
    <div
      id="program-enrolling-now-section"
      className="flex flex-col h-full w-full lg:container bg-center bg-cover bg-[url(/bg-images/bg-program-enroll.jpeg)] my-0 lg:mt-4 lg:mb-20"
    >
      <div className="flex flex-col items-center justify-center py-10 md:py-28 px-8">
        <h2 className="text-center m-0 md:mb-4 text-3xl">Enrolling Now</h2>
        {discountedPrice && originalPrice && (
          <p className="text-center text-lg">
            <FormattedPrice
              discountedPrice={discountedPrice}
              originalPrice={originalPrice}
              currency={currency}
            />
          </p>
        )}
        <p className="text-center">
          {/* MUST fix missing data to avoid needing a fallback value that risks inaccuracy*/}
          {count} {count > 1 ? "courses" : "course"} in {duration}
        </p>
        {isLoading ? (
          <Skeleton className="h-12 w-44 rounded-full" />
        ) : (
          <CTALink
            label={ctaText || "Pursue the Program"}
            type="brand"
            url={enrollmentUrl}
            size="lg"
            className="mx-auto"
            enable_tracking={false}
            conversionTrackingInfo={{
              productName: title,
              brand: brand,
              variant: productType,
              category: subject,
              customName: conversionType,
            }}
          />
        )}
        <div className="flex mt-3 max-w-96">
          <Checkbox
            id="offerings"
            className="mr-2 rounded-none bg-primary-foreground border-2 h-5 w-5 mt-1"
            onClick={() => {
              setEmailOptIn(!emailOptIn);
            }}
          />
          <label
            htmlFor="offerings"
            className="cursor-pointer text-xs font-medium peer-disabled:cursor-not-allowed text-primary"
          >
            I would like to receive email from {partnerNames} and learn about
            other offerings related to {title}.
          </label>
        </div>
      </div>
    </div>
  );
}
