"use client";
import Image from "next/image";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import { PartialPartner } from "@/lib/partner/types";
import { KeyboardArrowDown } from "@/components/icons";
import useCertificateCTAText from "@/monarch/rules/certificateCTA/useCertificateCTAText";
import { Program } from "@/lib/program/types";
import { ProcessedCourse } from "@/lib/course/types";
import HeroProgramsCtaTextTest from "./ctaTextTest";
import { Skeleton } from "@/components/ui/skeleton";
import LinkWithChildren from "@/components/Link/LinkWithChildren/LinkWithChildren";
import { ElementType } from "@/constants/tagular/main";

type Props = {
  title: string;
  marketingHook: string;
  owners: PartialPartner[];
  path: string;
  type: string;
  backgroundImage?: string;
  isProgramEnrollable?: boolean;
  originalPrice?: number;
  discountedPrice?: number;
  currency?: string;
  program: Program;
  courses: ProcessedCourse[];
  skus: string[];
  subject: string;
  emailOptIn?: boolean;
};

export default function HeroPrograms({
  title,
  marketingHook,
  owners,
  path,
  type,
  backgroundImage = "https://www.edx.org/images/experiments/ProgramDetails/data-science/hook-background-lg.jpg",
  isProgramEnrollable = true,
  originalPrice,
  discountedPrice,
  currency,
  program,
  courses,
  skus,
  subject,
  emailOptIn = false,
}: Props) {
  const programMap: { [key: string]: { marketingHook: string; type: string } } =
    {
      XSeries: {
        marketingHook: "Develop deep knowledge",
        type: "XSeries Program in",
      },
      MicroMasters: {
        marketingHook: "Accelerate your Master's Degree",
        type: "MicroMasters® Program in",
      },
      MicroBachelors: {
        marketingHook: "Drive your career forward",
        type: "MicroBachelors® Program in",
      },
      default: {
        marketingHook: "Drive your career forward",
        type: "Professional Certificate in",
      },
    };

  const defaultHook =
    programMap[type]?.marketingHook || programMap["default"].marketingHook;
  const subTitle = programMap[type]?.type || programMap["default"].type;

  const { isLoading, ctaText, conversionType } = useCertificateCTAText({
    isProgramEnrollable: isProgramEnrollable,
  });

  if (isProgramEnrollable && !isLoading && ctaText !== undefined) {
    return (
      <HeroProgramsCtaTextTest
        title={title}
        marketingHook={marketingHook}
        owners={owners}
        path={path}
        type={type}
        backgroundImage="https://www.edx.org/images/experiments/ProgramDetails/data-science/hook-background-lg.jpg"
        isProgramEnrollable={isProgramEnrollable}
        originalPrice={originalPrice}
        discountedPrice={discountedPrice}
        currency={currency}
        program={program}
        courses={courses}
        skus={skus}
        ctaText={ctaText}
        subject={subject}
        conversionType={conversionType}
        emailOptIn={emailOptIn}
      />
    );
  }

  return (
    <div className="justify-center relative">
      <div className="absolute inset-0 z-[-2] bg-putty-200">
        {backgroundImage && (
          <Image
            src={backgroundImage}
            layout="fill"
            alt="Gray decorative background design"
            className="object-cover object-center"
          />
        )}
      </div>

      <div className="text-putty-foreground pt-12 pb-1 px-4 container">
        <div className="text-light-foreground mb-6">
          <Breadcrumbs variant="dark" path={path || ""} pageName={title} />
        </div>
        <div className="lg:flex items-center">
          <h1 className="font-semibold text-primary pt-0 pb-4 mt-0 text-4xl md:text-7xl tracking-tight text-balance">
            {marketingHook || defaultHook}
          </h1>
        </div>
      </div>

      <div className="overflow-hidden shadow-md shadow-slate-600">
        <div className="min-h-[100px] mx-auto">
          <div className="lg:container flex p-0">
            <div
              className="bg-white flex flex-col items-start p-0 md:relative md:flex-row md:items-center md:p-0 md:w-full md:p-0 
            before:absolute before:w-[300px] before:top-0 before:bottom-0 before:left-[-300px] before:bg-white before:hidden before:md:block 
            after:absolute lg:after:w-[300px] after:top-0 after:bottom-0 after:right-[-300px] after:bg-primary-500 after:hidden after:md:block"
            >
              <div className="flex">
                {owners.map((owner) => (
                  <div key={owner.key} className="w-[180px] px-4">
                    <Image
                      className="mr-2"
                      width={180}
                      height={100}
                      src={owner.logoUrl || ""}
                      alt={owner.name || ""}
                    />
                  </div>
                ))}
              </div>
              <div className="py-6 px-4 text-base text-white bg-primary-500 w-screen md:w-full flex flex-col md:flex-row md:items-center gap-3">
                <div>
                  <span>{subTitle}</span>
                  <span className="text-xl block">{title}</span>
                </div>
                {isProgramEnrollable && isLoading && (
                  <Skeleton className="bg-slate-100/20 ml-auto h-12 w-56 rounded-full" />
                )}
                {isProgramEnrollable && !isLoading && (
                  <LinkWithChildren
                    class_name="mt-3 sm:mt-0 md:ml-auto cursor-pointer text-primary text-lg font-bold flex items-center justify-center bg-white px-8 py-2 min-w-40 whitespace-nowrap min-w-[210px]"
                    item={{
                      href: "#program-enrolling-now-section",
                      altText: "I'm interested",
                    }}
                    html_id="im-interested-CTA"
                    location="program-hero"
                    element_type={ElementType.Button}
                  >
                    I&lsquo;m interested
                    <span className="ml-3">
                      <KeyboardArrowDown />
                    </span>
                  </LinkWithChildren>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
