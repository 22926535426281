import AccordionText from "@/components/AccordionText";
import type { ProcessedCourse } from "@/lib/course/types";
import { ProgramType } from "@/lib/program/types";
import ProgramSummaryPathwayCourseItem from "./ProgramSummaryPathwayCourseItem";
import AccordionTextItem from "@/components/AccordionText/AccordionTextItem";
import parse from "html-react-parser";
import getProgramTypeString from "@/lib/program/utils/getProgramTypeString";
import checkRTL from "@/utils/functions/checkRTL";
import styles from "../../styles.module.css";
import sortBySortValue from "@/utils/functions/sortBySortValue";

interface ProgramSummaryPathwayProps {
  courses: ProcessedCourse[];
  partners: {
    key: string;
  }[];
  programType: ProgramType;
  title: string;
  creditRedemptionOverview: string;
  jobOutlookItems: {
    value: string | null;
  }[];
}

const getCertificateLabel = (programType: ProgramType) => {
  switch (programType) {
    case "MicroMasters":
      return "Certificate & Credit Pathways";
    case "Professional Certificate":
    case "XSeries":
      return `${programType} Details`;
    case "MicroBachelors":
      return "Program Certificate & College Credit";
    default:
      return `${programType} Certificate`;
  }
};

function ProgramSummaryPathway({
  courses,
  partners,
  programType,
  title,
  creditRedemptionOverview,
  jobOutlookItems,
}: ProgramSummaryPathwayProps) {
  const partnersString = partners.map((partner) => partner.key).join(" and ");
  const heading = `${partnersString}${partnersString.toLowerCase().endsWith("s") ? "'" : "'s"} ${title} ${getProgramTypeString(programType)}`;
  const filteredJobOutlookItems = jobOutlookItems.filter((item) => item.value);
  const isJobOutlookRtl = checkRTL(
    filteredJobOutlookItems.map((item) => item.value).join(""),
  );

  return (
    <div>
      <h2 className="text-center mb-0">Courses in this program</h2>
      <AccordionText title={heading} variant="pathway">
        {sortBySortValue(courses).map((course, index) => (
          <ProgramSummaryPathwayCourseItem
            key={course.productUuid}
            course={course}
            index={index}
          />
        ))}

        {creditRedemptionOverview && creditRedemptionOverview.length > 0 && (
          <AccordionTextItem
            trigger={getCertificateLabel(programType)}
            variant="pathway-dark"
            pathWayIconType="trencher"
          >
            <div className="pt-4">
              <div className="flex items-center gap-2 pb-2 mt-1">
                <div className={styles.accordionContent}>
                  {parse(creditRedemptionOverview)}
                </div>
              </div>
            </div>
          </AccordionTextItem>
        )}
        {filteredJobOutlookItems && filteredJobOutlookItems.length > 0 && (
          <AccordionTextItem
            trigger="Job Outlook"
            variant="pathway-dark"
            pathWayIconType="compass"
          >
            <div className="pt-4">
              <div className="flex items-center gap-2 pb-2 mt-1">
                <ul
                  dir={isJobOutlookRtl ? "rtl" : "ltr"}
                  className={`w-full list-inside ${isJobOutlookRtl ? "pr-4" : ""}`}
                  style={{ listStyleType: "circle" }}
                >
                  {filteredJobOutlookItems.map((jobItem) => {
                    return <li key={jobItem.value}>{jobItem.value}</li>;
                  })}
                </ul>
              </div>
            </div>
          </AccordionTextItem>
        )}
      </AccordionText>
    </div>
  );
}

export default ProgramSummaryPathway;
