import { ProcessedCourse } from "@/lib/course/types";
import getApplicableEntitlement from "./getApplicableEntitlement";
import getApplicableSeat from "./getApplicableSeat";

const getProductForCourse = (
  course: ProcessedCourse,
  applicableSeatTypes: string[],
) => {
  const entitlement = getApplicableEntitlement(
    course.entitlements,
    applicableSeatTypes,
  );
  const { activeCourseRun } = course;
  const seat = activeCourseRun
    ? getApplicableSeat(activeCourseRun.seats, applicableSeatTypes)
    : null;

  return entitlement || seat;
};

export default getProductForCourse;
