import CTALink from "@/components/CTA/ctaLink";
import { ElementType } from "@/constants/tagular/main";

const CategoryCTA = ({ programSubject }: { programSubject: string }) => {
  const lowerCaseProgramSubject = programSubject.toLowerCase();
  const btnLink =
    programSubject === "Computer Science"
      ? "/learn/computer-programming"
      : "/learn/data-analysis";

  return (
    <div className="container py-6 gap-4 lg:gap-6 flex flex-col justify-center items-center">
      <h2 className="text-primary-500  md:mb-4 text-4xl lg:text-6xl italic text-center font-black">
        Get started in {lowerCaseProgramSubject}
      </h2>
      <CTALink
        type="link"
        size="lg"
        className="text-center content-center items-center min-w-full md:min-w-32 py-2 justify-center
                   disabled:opacity-25 rounded-full px-4 hover:bg-primary-300 bg-primary-500 text-white whitespace-nowrap text-[16px]"
        label={`Browse other ${lowerCaseProgramSubject} courses`}
        url={btnLink}
        location="get-started"
        element_type={ElementType.Entry}
        html_id="topic-link"
      />
    </div>
  );
};

export default CategoryCTA;
