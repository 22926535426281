import Image from "next/image";

type Props = {
  id: string;
  attribution: string;
  program?: string;
  children?: React.ReactNode;
  logo?: string;
};

export default function TestimonialDark({
  id,
  attribution,
  program,
  children,
  logo,
}: Props) {
  return (
    <div
      key={id}
      className={`${
        logo
          ? "py-12 md:py-16 flex-col md:flex-row"
          : "flex-col max-w-[400px] lg:w-auto"
      } container flex basis-0 grow shrink w-full bg-primary p-6 md:p-[51px_97px] items-center text-center md:text-left`}
    >
      {logo && (
        <div className="col-span-12 md:col-span-3 flex-shrink-0 flex justify-center items-center mr-4">
          <div className="w-[170px] h-[170px] rounded-full bg-white flex items-center justify-center overflow-hidden">
            <div className="relative w-[200px] h-[200px]">
              <Image
                src={logo}
                alt="banner-logo"
                className="object-contain mt-0"
                fill
              />
            </div>
          </div>
        </div>
      )}

      <div className={logo ? "col-span-12 md:col-span-9 text-center" : ""}>
        <div className="[&_*]:text-primary-foreground [&_p]:mt-4">
          {children}
        </div>
        <p
          className={`text-primary-foreground ${logo ? "text-base" : "font-bold mt-5 mb-0"}`}
        >
          {logo && "— "}
          {attribution}
        </p>
        {program && <p className="text-primary-foreground">{program}</p>}
      </div>
    </div>
  );
}
