import { v4 as uuid } from "uuid";
import { ProgramCorporateEndorsement } from "@/lib/program/types";
import sortBySortValue from "@/utils/functions/sortBySortValue";
import ContentBlock from "@/components/ContentBlock";

function EndorsementItem({
  endorsement,
  key,
}: {
  endorsement: ProgramCorporateEndorsement;
  key: string;
}) {
  if (endorsement.individualEndorsements.length === 0) {
    return null;
  }
  const individualEndorsement = endorsement.individualEndorsements[0];
  const endorser =
    individualEndorsement.endorser.length > 0
      ? individualEndorsement.endorser[0]
      : null;
  const image = endorsement.image.length > 0 ? endorsement.image[0] : null;
  const fullName =
    `${endorser?.givenName || ""} ${endorser?.familyName || ""}`.trim();
  const attribution = [fullName, endorser?.title].filter(Boolean).join(", ");

  return (
    <div key={key} className="flex flex-row gap-4 justify-center items-start">
      <ContentBlock
        variant="quoted"
        imageurl={image?.src || ""}
        imagetitle={endorsement.corporationName || ""}
      >
        <div className="mb-0" style={{ lineHeight: "1.925rem" }}>
          <p className="text-lg leading-8 text-gray-800">
            {individualEndorsement.quote}
          </p>
          <p className="text-lg font-bold">{attribution}</p>
        </div>
      </ContentBlock>
    </div>
  );
}

interface ProgramEndorsementsProps {
  corporateEndorsements: ProgramCorporateEndorsement[];
}

function ProgramEndorsements({
  corporateEndorsements,
}: ProgramEndorsementsProps) {
  if (corporateEndorsements.length === 0) {
    return null;
  }
  return (
    <div className="mx-auto bg-inherit flex flex-col gap-4">
      <h2 className="text-primary-500 font-bold text-3xl mt-0 mb-0 text-left">
        Program endorsements
      </h2>
      {sortBySortValue(corporateEndorsements).map((endorsement) => {
        return <EndorsementItem key={uuid()} endorsement={endorsement} />;
      })}
    </div>
  );
}

export default ProgramEndorsements;
