import React from "react";
import { Accordion } from "@/components/ui/accordion";
import { createIdFromLabel } from "@/utils/functions/getIdLabel";

type Props = {
  children?: React.ReactNode;
  title?: string;
  titleClassName?: string;
};

export default function AccordionText({
  title,
  titleClassName = "",
  children,
}: Props) {
  // Only get valid Elements (without ,)
  const childrenArray = React.Children.toArray(children).filter((child) =>
    React.isValidElement(child),
  );
  const titleId = title ? createIdFromLabel(title) : "";
  return (
    <>
      {title && <a className="subnav-item" id={titleId}></a>}
      <div className="flex flex-col py-4 lg:pt-16">
        {title && (
          <h2 className={`mt-2 mb-4 text-3xl font-bold ${titleClassName}`}>
            {title}
          </h2>
        )}
        <Accordion
          type="multiple"
          className="flex flex-col gap-3 [&_button]:no-underline [&_button]:text-gray-dark [&_button:hover]:text-gray-dark"
        >
          {childrenArray}
        </Accordion>
      </div>
    </>
  );
}
