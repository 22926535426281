import { OFACText } from "@/components/CourseContent/OFACRestriction";

function OFACmessage() {
  return (
    <div className="bg-putty-400">
      <div className="max-w-[1280px] mx-auto">
        <div className="w-full flex flex-col gap-4 py-14 max-xl:px-4">
          <h2 className="m-0 text-xl">This course is unavailable.</h2>
          <p className="m-0 text-sm">{OFACText}</p>
        </div>
      </div>
    </div>
  );
}

export default OFACmessage;
