"use client";
import { ProgramStickyCTAProps } from "./types";
import formatProgramPrice from "../utils/formatProgramPrice";
import ProgramEnrollButton from "../ProgramEnrollButton";
import StickyCTAWrapper from "@/components/StickyCTAWrapper";
import useProgramRedesignTraffic from "@/monarch/rules/programRedesignTraffic/useProgramRedesignTraffic";
import { Skeleton } from "@/components/ui/skeleton";

function Loader() {
  return (
    <>
      <Skeleton
        className="h-12 min-w-44 bg-primary-light rounded-none"
        role="status"
      />
      <Skeleton className="h-12 min-w-44 bg-primary-light" role="status" />
    </>
  );
}

export default function ProgramStickyCTA({
  programData,
  courses,
  pricingData,
  skus,
  forceShow = false,
}: ProgramStickyCTAProps) {
  //Calling the hook will only consume it from the context at this point, not call monarch
  const { headerCta, isLoading } = useProgramRedesignTraffic();

  const showPriceInfo =
    typeof headerCta?.showPrice !== "undefined" ? headerCta?.showPrice : true;

  const pillPosition = headerCta?.pillPosition || "right";

  const ctaFlexDirection =
    pillPosition === "right" ? "flex-row" : "flex-row-reverse";

  return (
    <StickyCTAWrapper forceShow={forceShow}>
      <div
        className={`flex flex-col md:${ctaFlexDirection} p-4 pb-6 justify-center gap-2 md:gap-4 items-center md:h-[88px] bg-primary w-auto mx-auto`}
      >
        {isLoading ? <Loader /> : null}

        {!isLoading && showPriceInfo && pricingData ? (
          <div className="flex gap-1 text-white font-bold text-base">
            {pricingData.discountedPrice &&
            pricingData.originalPrice !== pricingData.discountedPrice ? (
              <div>
                <span className="sr-only">
                  {pricingData.originalPrice ? "Discounted price:" : "Price:"}
                </span>
                {`${formatProgramPrice(pricingData.discountedPrice, pricingData.currency)}${!pricingData.originalPrice ? pricingData.currency : ""}`}
              </div>
            ) : null}
            {pricingData.originalPrice &&
            pricingData.discountedPrice &&
            pricingData.originalPrice !== pricingData.discountedPrice ? (
              // Both prices are available and different, show original with line-through
              <div className="line-through opacity-50">
                <span className="sr-only">Original price:</span>
                {`${formatProgramPrice(pricingData.originalPrice, pricingData.currency)}${pricingData.currency}`}
              </div>
            ) : pricingData.originalPrice &&
              (!pricingData.discountedPrice ||
                pricingData.originalPrice === pricingData.discountedPrice) ? (
              // Only original price available OR prices are equal, show single price without line-through
              <div>
                <span className="sr-only">Price:</span>
                {`${formatProgramPrice(pricingData.originalPrice, pricingData.currency)}${pricingData.currency}`}
              </div>
            ) : null}
          </div>
        ) : null}
        {!isLoading ? (
          <div className="flex">
            <ProgramEnrollButton
              programData={programData}
              courses={courses}
              skus={skus}
            />
          </div>
        ) : null}
      </div>
    </StickyCTAWrapper>
  );
}
