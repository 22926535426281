import { ProcessedCourse } from "@/lib/course/types";
import IsUserInCourseWithCertificate from "./IsUserInCourseWithCertificate";
import getProductForCourse from "./getProductForCourse";
import { Enrollment } from "@/lib/partner-api/getEnrollments";
import { Entitlement } from "@/lib/partner-api/getEntitlements";

/**
 * Get the courses in a program that the user has not enrolled in with a
 * certificate and does not have an entitlement for.
 */
const getOutstandingCourses = (
  courses: ProcessedCourse[],
  enrollments: Enrollment[],
  entitlements: Entitlement[],
  applicableSeatTypes: string[],
) => {
  let foundSkus = false;
  let foundEnrollment = false;

  const filteredCourses = courses
    //  reject courses user is enrolled in with a certificate
    .filter((courseNode) => {
      const userHasEnrollment = IsUserInCourseWithCertificate({
        course: courseNode,
        enrollments: enrollments,
        entitlements: entitlements,
        applicableSeatTypes: applicableSeatTypes,
      });

      if (userHasEnrollment) {
        foundEnrollment = true;
      }

      return !userHasEnrollment;
    })
    //  reject courses user has entitlements for
    .filter((courseNode) => {
      const sku = getProductForCourse(courseNode, applicableSeatTypes);
      const skuIsUndefined = sku === undefined;

      if (!skuIsUndefined) {
        foundSkus = true;
      }

      return !skuIsUndefined;
    });

  return {
    courses: filteredCourses,
    hasSkus: foundSkus,
    hasEnrollment: foundEnrollment,
  };
};

export default getOutstandingCourses;
