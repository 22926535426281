import Link from "next/link";
import Icon from "@/components/icons";
import CTALink from "@/components/CTA/ctaLink";
import LinkWithChildren from "@/components/Link/LinkWithChildren/LinkWithChildren";
import { ElementType } from "@/constants/tagular/main";
import getSubjectToUse from "./utils/getSubjectToUse";
import getRelatedTopics from "./utils/getRelatedTopics";

function ProgramRelatedContent({ programSubject }: { programSubject: string }) {
  const subjectToUse = getSubjectToUse(programSubject);
  if (!subjectToUse) {
    return null;
  }
  const relatedTopics = getRelatedTopics(subjectToUse);
  const lowerCaseSubject = subjectToUse.toLowerCase();
  const ctaLink =
    subjectToUse === "Computer Science"
      ? "/learn/computer-programming"
      : "/learn/data-analysis";

  return (
    <div className="full-width bg-putty-200 px-4 py-16">
      <div className="container p-0">
        <h3 className="md:text-2xl mt-0">Get started in {lowerCaseSubject}</h3>
        <CTALink
          type="link"
          size="lg"
          className="px-0 md:text-lg underline"
          label={`Browse other ${lowerCaseSubject} courses`}
          url={ctaLink}
          element_type={ElementType.Entry}
          location="get-started"
          html_id="topic-link"
        />
        <h4 className="text-lg md:text-xl mt-16">Browse related topics</h4>
        <ul className="list-none flex flex-wrap gap-4">
          {relatedTopics.map((topic, index) => (
            <li key={topic.label}>
              <LinkWithChildren
                item={{ href: topic.href, altText: topic.label }}
                class_name="flex items-center gap-2 md:text-base text-info-dark group"
                element_type={ElementType.Entry}
                html_id="topic-link"
                location="get-started"
                index={index}
              >
                <span className="group-hover:underline">{topic.label}</span>
                <Icon type="arrow-right-alt" />
              </LinkWithChildren>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default ProgramRelatedContent;
