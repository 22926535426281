import React from "react";
import { v4 as uuidv4 } from "uuid";
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import styles from "./index.module.css";
import pathwayStyles from "../variants/Pathway/index.module.css";
import titleTopStyles from "../variants/TitleTop/index.module.css";
import titleLeftStyles from "../variants/TitleLeft/index.module.css";
import Icon from "@/components/icons";
import { IconTypes } from "@/components/icons/types";

type Props = {
  children?: React.ReactNode;
  trigger: string | React.ReactNode;
  value?: string;
  variant?:
    | "default"
    | "pathway"
    | "pathway-dark"
    | "title-left"
    | "title-top"
    | undefined;
  pathWayIconType?: IconTypes;
};

export default function AccordionTextItem({
  children,
  trigger,
  value,
  variant,
  pathWayIconType,
}: Props) {
  let variantStyles;

  switch (variant) {
    case "pathway-dark":
    case "pathway":
      variantStyles = pathwayStyles;
      break;
    case "title-top":
      variantStyles = titleTopStyles;
      break;
    case "title-left":
      variantStyles = titleLeftStyles;
      break;
    default:
      variantStyles = styles;
      break;
  }

  const getValue = () => {
    if (value) {
      return value;
    }
    if (typeof trigger === "string") {
      return trigger;
    }
    return uuidv4();
  };

  return (
    <AccordionItem
      value={getValue()}
      className={`${styles.item} ${variantStyles.item ?? ""}`}
    >
      <AccordionTrigger
        className={`${styles.trigger} ${variantStyles.trigger ?? ""}`}
      >
        {(variant === "pathway" || variant === "pathway-dark") && (
          <div className="absolute top-[15px] left-[15px]">
            <div
              className={`flex justify-center items-center h-9 w-9 border border-2 border-primary rounded-full ${variant === "pathway-dark" ? "bg-primary text-white" : "bg-info-light"}`}
            >
              <Icon
                type={pathWayIconType || "library-books"}
                pathProps={{
                  fill: variant === "pathway-dark" ? "white" : "currentcolor",
                  height: 16,
                  width: 16,
                }}
              />
            </div>
          </div>
        )}
        {typeof trigger === "string" ? <span>{trigger}</span> : trigger}
      </AccordionTrigger>
      <AccordionContent
        className={`${styles.content} ${variantStyles.content ?? ""}`}
      >
        {children}
      </AccordionContent>
    </AccordionItem>
  );
}
