import AccordionContent from "@/components/AccordionContent";
import Icon from "@/components/icons";
import VideoPlayer from "@/components/VideoPlayer";
import type { ProcessedCourse } from "@/lib/course/types";
import type { PacingType, Program } from "@/lib/program/types";
import checkRTL from "@/utils/functions/checkRTL";
import sortBySortValue from "@/utils/functions/sortBySortValue";
import ProgramSummaryPathway from "./components/ProgramSummaryPathway";
import ProgramSummaryStats from "./components/ProgramSummaryStats";
import styles from "./styles.module.css";

interface ProgramSummaryProps {
  courses: ProcessedCourse[];
  currency?: string;
  discountedPrice?: number;
  duration?: string | null;
  originalPrice?: number;
  pacingType?: PacingType;
  program: Program;
}

function ProgramSummary({
  courses,
  currency,
  discountedPrice,
  duration,
  originalPrice,
  program,
}: ProgramSummaryProps) {
  const {
    creditValue,
    expectedLearningItems,
    overview,
    partners,
    productType: programType,
    title,
    creditRedemptionOverview,
    jobOutlookItems,
  } = program;

  const retiringSoon =
    program.customizations?.catalogPage?.retiringSoon ?? undefined;

  const video = program.video.length > 0 ? program.video[0] : null;
  const filteredExpectedLearningItems = sortBySortValue(
    expectedLearningItems.filter((item) => item.value),
  );
  const areLearningItemsRTL = checkRTL(
    filteredExpectedLearningItems.map((item) => item.value).join(""),
  );

  return (
    <div className="max-w-screen-xl bg-gradient-to-b from-white to-putty-200 mx-auto p-6 lg:p-12">
      {retiringSoon && (
        <div className="flex justify-center items-center gap-2 bg-warning-light border border-putty-500 rounded shadow-md p-8 mb-8 text-gray-dark">
          <Icon type="warning" />
          <p className="m-0 text-sm">This program is retiring soon</p>
        </div>
      )}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-12">
        <div className="lg:col-span-2">
          {filteredExpectedLearningItems &&
            filteredExpectedLearningItems.length > 0 && (
              <div>
                <h2 className="text-center mt-0 text-2xl">
                  What you will learn
                </h2>
                <ul
                  className="list-disc flex flex-col gap-1 list-inside mb-8"
                  dir={areLearningItemsRTL ? "rtl" : "ltr"}
                >
                  {filteredExpectedLearningItems.map((item) => (
                    <li key={item.value} className="text-base">
                      {item.value}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          {video?.src && (
            <VideoPlayer
              title={title}
              videoUrl={video.src}
              videoClassName="w-full h-full"
            />
          )}
          <AccordionContent
            title="Program Overview"
            triggerClassName="!bg-white no-underline text-lg text-primary hover:text-primary border border-putty-500 py-5 mt-8"
            iconClassName="text-primary"
            contentClassName={`bg-white px-5 py-0 border border-t-0 ${styles.accordionContent}`}
          >
            <div dangerouslySetInnerHTML={{ __html: overview }} />
          </AccordionContent>
        </div>
        <ProgramSummaryStats
          courses={courses}
          creditValue={creditValue}
          currency={currency}
          duration={duration}
          originalPrice={originalPrice}
          discountedPrice={discountedPrice}
          programType={programType}
        />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-12">
        <div className="lg:col-span-2">
          <ProgramSummaryPathway
            courses={courses}
            partners={partners}
            programType={programType}
            title={title}
            creditRedemptionOverview={creditRedemptionOverview}
            jobOutlookItems={jobOutlookItems}
          />
        </div>
        <div />
      </div>
    </div>
  );
}

export default ProgramSummary;
