import { Program } from "@/lib/program/types";

export default function getProgramFromSubject(programData: Program) {
  const firstEnglishSubject = programData.subjects.find(
    (subject) => subject.languageCode === "en",
  );

  const programSubject =
    programData.subjectEn || firstEnglishSubject?.name || "";

  return programSubject;
}
