export const programMap: {
  [key: string]: { marketingHook: string; type: string };
} = {
  XSeries: {
    marketingHook: "Develop deep knowledge",
    type: "XSeries Program in",
  },
  MicroMasters: {
    marketingHook: "Accelerate your Master's Degree",
    type: "MicroMasters® Program in",
  },
  MicroBachelors: {
    marketingHook: "Drive your career forward",
    type: "MicroBachelors® Program in",
  },
  default: {
    marketingHook: "Drive your career forward",
    type: "Professional Certificate in",
  },
};
