import React from "react";
import Icon from "@/components/icons";
import { Accordion } from "@/components/ui/accordion";

type Props = {
  children?: React.ReactNode;
  title: string;
};

export default function AccordionTextPathway({ title, children }: Props) {
  // Only get valid Elements (without ,)
  const childrenArray = React.Children.toArray(children).filter((child) =>
    React.isValidElement(child),
  );
  return (
    <div className="relative overflow-hidden">
      <div className="flex flex-col relative">
        <div className="absolute top-3.5 left-2">
          <Icon type="location-on" svgProps={{ width: 48, height: 63 }} />
        </div>
        <h3 className="text-base md:text-xl mb-6 pl-16">{title}</h3>
        <Accordion
          type="multiple"
          className="flex flex-col [&_button]:no-underline [&_button]:text-gray-dark [&_button:hover]:text-gray-dark"
        >
          {childrenArray}
        </Accordion>
      </div>
    </div>
  );
}
