"use client";
import parse from "html-react-parser";
import Link from "next/link";
import { useMemo } from "react";
import AccordionTextItem from "@/components/AccordionTextRefresh/AccordionTextItemRefresh";
import Icon from "@/components/icons";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import type { Course, ProcessedCourse } from "@/lib/course/types";

interface ProgramSummaryPathwayCourseItemProps {
  course: ProcessedCourse;
  index: number;
}
import { PacingType } from "@/lib/program/types";
import getTotalEstimatedEffortInHoursPerWeek from "@/lib/program/utils/getTotalEstimatedEffortInHoursPerWeek";
import LinkWithChildren from "@/components/Link/LinkWithChildren/LinkWithChildren";
import { ElementType } from "@/constants/tagular/main";

function PathwayTrigger({ course }: { course: Course }) {
  const { prerequisitesHtml, productName, productUuid } = course;

  // Only display popover for "Data Analysis in Social Science — Assessing Your Knowledge."
  if (productUuid !== "48708e7e-0152-4a4b-ad4c-2dadce29c87f") {
    return <span>{productName}</span>;
  }

  return (
    <div className="flex">
      <span>{productName}</span>
      <div className="ml-4">
        <Popover>
          <PopoverTrigger onClick={(e) => e.stopPropagation()}>
            <div className="[&>svg]:!h-auto [&>svg]:!w-auto">
              <Icon type="info" />
            </div>
          </PopoverTrigger>
          <PopoverContent className="rounded-none">
            <div className="[&>p:last-child]:mb-0 hover:[&_a]:underline">
              {parse(prerequisitesHtml)}
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
}

function ProgramSummaryPathwayCourseItem({
  course,
  index,
}: ProgramSummaryPathwayCourseItemProps) {
  const activeCourseRun = course.activeCourseRun;
  const startDate = useMemo(
    () =>
      activeCourseRun && typeof activeCourseRun.start === "number"
        ? new Date(activeCourseRun.start)
        : null,
    [activeCourseRun],
  );

  const hasStarted = useMemo(() => {
    if (!startDate) {
      return false;
    }
    const today = new Date();
    return startDate && today >= startDate;
  }, [startDate]);

  const hoursPerWeekFormatted = getTotalEstimatedEffortInHoursPerWeek([course]);

  return (
    <AccordionTextItem
      key={course.productUuid}
      trigger={<PathwayTrigger course={course} />}
      value={course.productName}
      variant="pathway"
    >
      <div className="flex flex-col md:flex-row justify-between md:items-center gap-6">
        <div className="[&_p]:mb-0">
          {activeCourseRun &&
          activeCourseRun.pacingType === PacingType.INSTRUCTORPACED &&
          startDate ? (
            <div className="flex items-center gap-2">
              <span className="font-bold">
                {hasStarted ? "Started" : "Starts"}{" "}
                {new Intl.DateTimeFormat("en-US").format(startDate)}
              </span>
            </div>
          ) : null}
          {activeCourseRun?.weeksToComplete ? (
            <div className="flex items-center gap-2">
              <span className="font-bold">
                {`${hoursPerWeekFormatted}, for ${activeCourseRun.weeksToComplete} weeks`}
              </span>
            </div>
          ) : null}
          {!activeCourseRun && (
            <div className="flex items-center gap-2">
              <span className="font-bold">Not currently available</span>
            </div>
          )}
          {parse(course.productShortDescription)}
        </div>
        <div>
          {activeCourseRun && (
            <LinkWithChildren
              item={{
                href: `/${course.productUrlSlug}`,
                altText: course.productName + " View the course",
              }}
              target="_blank"
              element_type={ElementType.Entry}
              class_name="text-gray-800 underline underline-offset-4"
              location="program-courses"
              html_id="view-more-link"
              index={index}
            >
              <span className="flex items-center gap-2 text-base">
                View course
                <Icon
                  type="launch"
                  svgProps={{
                    width: "24",
                    height: "24",
                    viewBox: "0 0 24 18",
                    className: "hidden md:block",
                  }}
                />
              </span>
            </LinkWithChildren>
          )}
        </div>
      </div>
    </AccordionTextItem>
  );
}

export default ProgramSummaryPathwayCourseItem;
