"use client";
import React, { useState } from "react";
import { ExpandableInsightsProps } from "./types";
import IndustryInsightItem from "./IndustryInsightItem";

export default function ExpandableInsights({
  insights,
}: ExpandableInsightsProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const visibleCountMobile = 2;
  const visibleCountDesktop = 4;

  return (
    <div className="bg-inherit flex flex-col gap-12">
      <h2 className="text-primary-500 font-bold text-[36px] mt-0 mb-0 text-left">
        Industry insights
      </h2>

      <div className="grid gap-6 md:grid-cols-2">
        {insights
          .slice(0, isExpanded ? insights.length : visibleCountMobile)
          .map(({ value }, index) => (
            <IndustryInsightItem
              key={`insight-${index + 1}`}
              value={value}
              isMobile
            />
          ))}

        {insights
          .slice(0, isExpanded ? insights.length : visibleCountDesktop)
          .map(({ value }, index) => (
            <IndustryInsightItem key={`insight-${index + 1}`} value={value} />
          ))}
      </div>

      {insights.length > visibleCountMobile && (
        <button
          data-testid="expandable-insights-trigger"
          onClick={() => setIsExpanded(!isExpanded)}
          className="text-base text-primary font-semibold underline self-start block md:hidden"
        >
          {isExpanded ? "Show less" : "Show more"}
        </button>
      )}

      {insights.length > visibleCountDesktop && (
        <button
          data-testid="expandable-insights-trigger"
          onClick={() => setIsExpanded(!isExpanded)}
          className="text-base text-primary font-semibold underline self-start hidden md:block"
        >
          {isExpanded ? "Show less" : "Show more"}
        </button>
      )}
    </div>
  );
}
