import { ProgramType } from "@/lib/program/types";

export const getExpertInstructionLabel = (programType: ProgramType) => {
  switch (programType) {
    case ProgramType.MICROMASTERS:
      return "graduate-level";
    case ProgramType.PROFESSIONALCERTIFICATE:
      return "skill-building";
    default:
      return "high-quality";
  }
};
