import { Seat } from "@/lib/courseRun/types";

/**
 * Retrieve a seat with applicable seat type from the course run seats.
 */
const getApplicableSeat = (seats: Seat[] = [], applicableSeatTypes: string[]) =>
  seats.find((seat) =>
    applicableSeatTypes.some((seatType) => {
      return seatType === seat.type;
    }),
  );

export default getApplicableSeat;
