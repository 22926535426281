import AccordionText from "@/components/AccordionTextRefresh";
import type { ProcessedCourse } from "@/lib/course/types";
import ProgramSummaryPathwayCourseItem from "./ProgramSummaryPathwayCourseItem";
import sortBySortValue from "@/utils/functions/sortBySortValue";

interface ProgramSummaryPathwayProps {
  courses: ProcessedCourse[];
}

function ProgramSummaryPathway({ courses }: ProgramSummaryPathwayProps) {
  return (
    <div id="courses" className="flex flex-col gap-6">
      <h2 className="my-0 text-primary text-3xl font-bold">
        Courses in this program ({courses.length})
      </h2>
      <AccordionText variant="pathway">
        {sortBySortValue(courses).map((course, index) => (
          <ProgramSummaryPathwayCourseItem
            key={course.productUuid}
            course={course}
            index={index}
          />
        ))}
      </AccordionText>
    </div>
  );
}

export default ProgramSummaryPathway;
