import getOutstandingCourses from "./getOutstandingCourses";
import isUserLoggedIn from "@/lib/auth/isUserLoggedIn";
import { LMS_DOMAIN } from "@/lib/course/utils/constants";
import getSkuQueryStringForPartialBundle from "./getSkuQueryStringForPartialBundle";
import getSkuQueryStringForFullBundle from "./getSkuQueryStringForFullBundle";
import { Program } from "../../types";
import { ProcessedCourse } from "@/lib/course/types";
import { Enrollment } from "@/lib/partner-api/getEnrollments";
import { Entitlement } from "@/lib/partner-api/getEntitlements";
import {
  getCommerceUrlBase,
  isCoordinatorEnabledForPrograms,
} from "@/utils/functions/commerce";

export default function getEnrollmentUrl(
  programData: Program,
  courses: ProcessedCourse[],
  skus: string[],
  enrollments: Enrollment[],
  entitlements: Entitlement[],
  emailOptIn: boolean = false,
) {
  const { applicableSeatTypes: applicableSeatTypeData, leadFormUrl } =
    programData;

  const applicableSeatTypes =
    applicableSeatTypeData?.map((seatType) => seatType.slug) || [];

  const coursesInfo =
    enrollments.length + entitlements.length === 0
      ? {
          courses,
          hasSkus: false,
          hasEnrollment: false,
        }
      : getOutstandingCourses(
          courses,
          enrollments,
          entitlements,
          applicableSeatTypes,
        );

  const userFullyEnrolled =
    isUserLoggedIn() && coursesInfo.courses.length === 0;
  if (userFullyEnrolled) {
    return `${LMS_DOMAIN}/dashboard/programs/`;
  }

  // Example: "https://ecommerce.edx.org/basket/add/?bundle=1859f52c-3fc0-43fe-a8a1-13660c77e9c3&sku=FD8CE26&sku=273F1D4&sku=6706D1F"
  let url = new URL(leadFormUrl);

  // remove any existing skus
  url.searchParams.delete("sku");

  const skuQueryString =
    coursesInfo.hasEnrollment || coursesInfo.hasSkus
      ? getSkuQueryStringForPartialBundle(
          coursesInfo.courses,
          applicableSeatTypes,
        )
      : getSkuQueryStringForFullBundle(skus);

  if (isCoordinatorEnabledForPrograms()) {
    // "https://commerce-coordinator.edx.org/lms/payment_page_redirect/?bundle=1859f52c-3fc0-43fe-a8a1-13660c77e9c3&sku=FD8CE26&sku=273F1D4&sku=6706D1F"
    const coordinatorBase = getCommerceUrlBase();

    if (coordinatorBase) {
      const ccUrl = new URL(`${coordinatorBase}`);
      ccUrl.pathname = "/lms/payment_page_redirect/";
      ccUrl.search = url.searchParams.toString();
      url = ccUrl;
    }
  }

  return `${url}${skuQueryString}&email_opt_in=${emailOptIn}`;
}
