"use client";
import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  Dispatch,
} from "react";

type State = {
  emailOptIn: boolean;
};

type Action = { type: "SET_EMAIL_OPT_IN"; payload: boolean };

const ProgramContext = createContext<{
  state: State;
  dispatch: Dispatch<Action>;
}>({
  state: { emailOptIn: false },
  dispatch: () => null,
});

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_EMAIL_OPT_IN":
      return { ...state, emailOptIn: action.payload };
    default:
      return state;
  }
};

type ProgramContextProviderProps = Partial<State> & {
  children: ReactNode;
};

export const ProgramContextProvider = ({
  children,
  emailOptIn,
}: ProgramContextProviderProps) => {
  const initialState: State = {
    emailOptIn: emailOptIn || false,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ProgramContext.Provider value={{ state, dispatch }}>
      {children}
    </ProgramContext.Provider>
  );
};

export const useProgramContext = () => useContext(ProgramContext);
