import Image from "next/image";

type Props = {
  id: string;
  attribution: string;
  program?: string;
  imageUrl?: string;
  imageAlt?: string;
  children?: React.ReactNode;
};

export default function TestimonialDefault({
  id,
  attribution,
  program,
  imageUrl,
  imageAlt,
  children,
}: Props) {
  return (
    <div
      className="w-full my-4 text-center flex flex-col items-center"
      key={id}
    >
      {imageUrl && (
        <div className="flex justify-center">
          <Image
            className="rounded-full my-0 w-[150px] h-[150px] object-cover"
            src={imageUrl}
            alt={imageAlt || ""}
            width={150}
            height={150}
          />
        </div>
      )}
      <h3 className="text-sm my-2">{attribution}</h3>
      {program && <p>{program}</p>}
      <div>{children}</div>
    </div>
  );
}
