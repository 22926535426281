//sorts any array of items that have sortValue prop

const sortBySortValue = <
  T extends { sortValue?: number | null; sortOrder?: number | null },
>(
  items: T[],
): T[] => {
  return items.sort((a, b) => {
    const aValue = a.sortValue ?? a.sortOrder ?? -1;
    const bValue = b.sortValue ?? b.sortOrder ?? -1;
    return aValue - bValue;
  });
};

export default sortBySortValue;
