import React from "react";
import { IndustryInsightItemProps } from "./types";

export default function IndustryInsightItem({
  value,
  isMobile,
}: IndustryInsightItemProps) {
  const sourceRegex = /\(Source:.*?\)/gi;
  const parts = value.split(sourceRegex);
  const sourceMatch = value.match(sourceRegex);

  return (
    <p
      className={`text-gray-800 font-bold text-[22px] pl-5 flex items-start ${
        isMobile ? "md:hidden" : "hidden md:flex"
      }`}
    >
      <span className="mr-2">•</span>
      <span>
        {parts[0]}
        {sourceMatch && <span className="font-normal"> {sourceMatch[0]}</span>}
        {parts[1] && <span className="font-normal">{parts[1]}</span>}
      </span>
    </p>
  );
}
