import { CourseRun } from "../courseRun/types";

export function getAllInstructorsFromCourse(
  courses?: { courseRuns?: CourseRun[] }[],
) {
  if (!courses) return [];

  const courseRuns = courses.flatMap((course) => course.courseRuns || []);

  const instructors = courseRuns.flatMap(
    (courseRun) => courseRun.instructors || [],
  );

  const uniqueInstructors = Array.from(
    new Map(
      instructors.map((instructor) => [instructor.id, instructor]),
    ).values(),
  );

  return uniqueInstructors;
}
