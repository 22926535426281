import { Course } from "@/lib/course/types";
import isEnrolledInCourseWithCertificate from "./isEnrolledInCourseWithCertificate";
import { Enrollment } from "@/lib/partner-api/getEnrollments";
import { Entitlement } from "@/lib/partner-api/getEntitlements";

const IsUserInCourseWithCertificate = (args: {
  course: Course;
  enrollments: Enrollment[];
  applicableSeatTypes: string[];
  entitlements: Entitlement[];
}) => {
  const { course, enrollments, applicableSeatTypes, entitlements } = args;
  const { courseRunKeys, productUuid } = course;

  return (
    isEnrolledInCourseWithCertificate(
      courseRunKeys,
      enrollments,
      applicableSeatTypes,
    ) ||
    entitlements.some(
      (entitlement) =>
        entitlement.courseUuid === productUuid &&
        entitlement.expirationDate === null,
    )
  );
};

export default IsUserInCourseWithCertificate;
