/**
  Determines whether the input string is predominantly written in a Right-to-Left (RTL) script (such as Hebrew,
  Arabic, etc.)
*/
export default function checkRTL(str: string) {
  if (str) {
    const count = (
      str.match(/[\u04c7-\u0591\u05D0-\u05EA\u05F0-\u05F4\u0600-\u06FF]/g) || []
    ).length;
    return count / str.length > 0.3;
  }
  return false;
}
