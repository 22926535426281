"use client";
import ProgramEnrollButton, {
  ProgramEnrollButtonProps,
} from "../ProgramEnrollButton";

export default function MidPageCTA(props: ProgramEnrollButtonProps) {
  return (
    <div className="flex flex-col text-center gap-4">
      <h2 className="text-xl font-bold my-0">Ready to advance your career?</h2>
      <div className="self-center w-full sm:w-auto px-6">
        <ProgramEnrollButton {...props} />
      </div>
    </div>
  );
}
