export const firstTitle = "Propelling";
export const firstDescription =
  "Drive your career forward with university-backed credit programs and verified certificates";

export const secondTitle = "Convenient";
export const secondDescription =
  "Study and demonstrate knowledge on your schedule";

export const thirdTitle = "Flexible";
export const thirdDescription = "Try a course before you pay";

export const fourthTitle = "Supportive";
export const fourthDescription =
  "Learn with university partners and peers from around the world";

export const image =
  "https://www.edx.org/cdn-cgi/image/width=400,height=312,format=webp,quality=75/assets/world_map.png";
