import Image from "next/image";
import * as Info from "./info";

export default function ProgramContentBlock() {
  return (
    <div className="container my-9 pb-20">
      <div className="flex flex-col lg:grid lg:grid-cols-2">
        <div>
          <p className="text-base mb-3">
            Whether you are looking to accelerate your career, earn a degree, or
            learn something for personal reasons,{" "}
            <strong>edX has the courses for you.</strong>
          </p>
          <div className="flex flex-col md:grid md:grid-cols-2 gap-4">
            <div>
              <div className="mt-0 mb-2 text-xl font-bold">
                {Info.firstTitle}
              </div>
              <p className="m-0">{Info.firstDescription}</p>
            </div>
            <div>
              <div className="mt-0 mb-2 text-xl font-bold">
                {Info.secondTitle}
              </div>
              <p className="m-0">{Info.secondDescription}</p>
            </div>
            <div>
              <div className="mt-0 mb-2 text-xl font-bold">
                {Info.thirdTitle}
              </div>
              <p className="m-0">{Info.thirdDescription}</p>
            </div>
            <div>
              <div className="mt-0 mb-2 text-xl font-bold">
                {Info.fourthTitle}
              </div>
              <p className="m-0">{Info.fourthDescription}</p>
            </div>
          </div>
        </div>
        <div className="hidden lg:flex justify-center items-center">
          <Image
            src={Info.image}
            alt="global education"
            width={400}
            height={312}
          />
        </div>
      </div>
    </div>
  );
}
