"use client";
import CTALink from "@/components/CTA/variants/CtaLink";
import type { ProcessedCourse } from "@/lib/course/types";
import useClientEnrollments from "@/lib/partner-api/useClientEnrollments";
import useClientEntitlements from "@/lib/partner-api/useClientEntitlements";
import type { Program } from "@/lib/program/types";
import { getBrandString } from "@/utils/tagular/helpers";
import useCertificateCTAText from "@/monarch/rules/certificateCTA/useCertificateCTAText";
import { Skeleton } from "@/components/ui/skeleton";
import getProgramFromSubject from "../utils/getSubjectFromProgram";
import useEmailOptIn from "../ProgramDetailContext/hooks/useEmailOptIn";
import getEnrollmentUrl from "@/lib/program/utils/programEnroll/getEnrollmentUrl";

export type ProgramEnrollButtonProps = {
  courses: ProcessedCourse[];
  programData: Program;
  skus: string[];
};

export default function ProgramEnrollButton({
  courses,
  programData,
  skus,
}: ProgramEnrollButtonProps) {
  const { enrollments } = useClientEnrollments();
  const { entitlements } = useClientEntitlements();
  const { isLoading, ctaText, conversionType } = useCertificateCTAText({
    isProgramEnrollable: true,
  }); //This is only ever rendered if the program is enrollable
  const { emailOptIn } = useEmailOptIn();

  const programSubject = getProgramFromSubject(programData);

  const { productType, title } = programData;
  const brand = getBrandString(programData);

  const enrollmentUrl = getEnrollmentUrl(
    programData,
    courses,
    skus,
    enrollments,
    entitlements,
    emailOptIn,
  );

  return (
    <>
      {isLoading ? (
        <Skeleton className="h-12 min-w-44 rounded-full w-full" role="status" />
      ) : (
        <CTALink
          label={ctaText || "Earn a certificate"}
          type="brand"
          url={enrollmentUrl}
          size="lg"
          className="mx-auto py-2 px-6 w-full lg:h-auto text-[16px]"
          enable_tracking={false}
          conversionTrackingInfo={{
            productName: title,
            brand: brand,
            variant: productType,
            category: programSubject,
            customName: conversionType,
          }}
        />
      )}
    </>
  );
}
